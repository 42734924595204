<section>
  <div class="background">
    <!--START HERO-->
    <div class="hero">
      <div class="d-flex align-items-start">
        <h2 class="font-title">Welcome to EnableAll</h2>
      </div>
      <div class="d-flex align-items-start">
        <h1 class="font-title font-bold">
          The World's First Truly<br />Accessible Marketplace
        </h1>
      </div>
    </div>
    <!--END HERO-->
  </div>
</section>
<!--ABOUT US-->
<section class="about-us">
  <div class="about-us-header pb-5 text-center">
    <h2 class="font-bold pt-5">Why Use EnableAll?</h2>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="card bg-theme-surface text-theme-on-surface">
        <div class="justify-content-center">
          <div class="icon a11y border-radius-100">
            <img
              ngSrc="assets/images/icons/Group22.svg"
              width="60"
              height="60"
              alt="a white human figure in a pink circle"
              data-sizes="auto"
              class="lazyload p-0"
            />
          </div>
          <div class="content text-center">
            <div class="pt-3 pb-3">
              <h3 class="font-bold">Leading the change</h3>
            </div>

            <p class="text-theme-on-background">
              We ensure nobody is left out, our site is the first AAA accessible
              marketplace.
            </p>
            <div data-sizes="auto" class="lazyload img-tile"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="card bg-theme-surface text-theme-on-surface">
        <div class="justify-content-center">
          <div class="icon marketplace border-radius-100">
            <img
              ngSrc="assets/images/icons/Group26.svg"
              alt="a white house figures on a pink circle"
              width="60"
              height="60"
              data-sizes="auto"
              class="lazyload p-0"
            />
          </div>
          <div class="content text-center">
            <div class="pt-3 pb-3">
              <h3 class="font-bold">Support retailers</h3>
            </div>

            <p class="text-theme-on-background">
              By using EnableAll you are directly supporting retailers that are
              supporting those with disabilities.
            </p>
            <div data-sizes="auto" class="lazyload img-tile second-tile"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--END ABOUT US-->

<!--ICONS-->
<section class="icons">
  <div class="about-us-icons">
    <div class="row">
      <!--a11y-->
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="icon a11y blue border-radius-100">
          <img
            ngSrc="assets/images/icons/1_Built_with_accessibility.svg"
            alt="a white human figure in a pink circle"
            width="60"
            height="60"
            data-sizes="auto"
            class="lazyload"
          />
        </div>
        <p class="text-theme-on-background font-bold">
          Built with accessibility at the core
        </p>
      </div>

      <!--marketplace-->
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="icon marketplace purple border-radius-100">
          <img
            ngSrc="assets/images/icons/2_Shop_thousands.svg"
            alt="a white and pink bag"
            data-sizes="auto"
            width="60"
            height="60"
            class="lazyload"
          />
        </div>
        <p class="text-theme-on-background font-bold">
          Shop thousands of inclusive sellers
        </p>
      </div>

      <!--trajectory-->
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="icon trajectory green border-radius-100">
          <img
            ngSrc="assets/images/icons/3_Support_brands.svg"
            width="60"
            height="60"
            alt="a group of people in a pink circle"
            data-sizes="auto"
            class="lazyload"
          />
        </div>
        <p class="text-theme-on-background font-bold">
          Support brands who care about inclusivity
        </p>
      </div>

      <!--paint-->
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="icon paint red border-radius-100">
          <img
            ngSrc="assets/images/icons/4_A_true_accessibility.svg"
            alt="a pink circle with a white computer screen"
            data-sizes="auto"
            width="60"
            height="60"
            class="lazyload"
          />
        </div>
        <p class="text-theme-on-background font-bold">
          A true accessibility first interface
        </p>
      </div>

      <!--group-->
      <div class="col-sm-6 col-md-4 col-lg-2">
        <div class="icon group blue border-radius-100">
          <img
            ngSrc="assets/images/icons/5_Designed_with_you.svg"
            alt="a white paint brush in a pink circle"
            width="60"
            height="60"
            data-sizes="auto"
            class="lazyload"
          />
        </div>
        <p class="text-theme-on-background font-bold">
          Designed with you in mind, no matter your circumstance
        </p>
      </div>
    </div>
  </div>
</section>
<!--END ICONS-->
