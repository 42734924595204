import { Injectable } from '@angular/core';
import { Toast } from '@ea/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly _toast$: Subject<Toast> = new Subject<Toast>();
  readonly toast$: Observable<Toast>;
  constructor() {
    this.toast$ = this._toast$.asObservable();
  }
  show(message: string) {
    /**
     * 6 seconds is the default duration for a toast.
     * Each additional 120 words will add 1 second to the duration.
     */
    this._toast$.next({
      message,
      duration: 6000 + Math.ceil(message.split(' ').length / 120) * 100,
    });
  }

  // show(message: string) {
  //   /**
  //    * 6 seconds is the default duration for a toast.
  //    * Each additional 50 words will add 1 second to the duration.
  //    */
  //   this._toast$.next({
  //     message,
  //     duration: 600000 + Math.ceil(message.split(' ').length / 50) * 100,
  //   });
  // }
}
