import {
  ILogin,
  IUser,
  OtpLogin,
  OtpRequest,
  Recovery,
  Register,
  ResetData,
  UpdateUser,
} from '@ea/models';
import { createAction, props } from '@ngrx/store';

// LOGIN USER ACTIONS

export const init = createAction('[auth] INIT');

export const login = createAction(
  '[Auth] Login User',
  props<{ payload: ILogin, redirecrRoute : string }>(),
);

export const set = createAction('[Auth] Set', props<{ user: IUser }>());

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ user: IUser, redirecrRoute? :string }>(),
);

export const loginFail = createAction(
  '[Auth] Login Fail',
  props<{ error: Error }>(),
);

export const logout = createAction(
  '[Auth] Logout',
  props<{ redirectTo: string }>(),
);

// REGISTER USER ACTIONS
export const register = createAction(
  '[Auth] Register User',
  props<{ payload: Register }>(),
);

export const registerSuccess = createAction(
  '[Auth] Register Success',
  props<{ uuid: string }>(),
);

export const registerFail = createAction(
  '[Auth] Register Fail',
  props<{ error: Error }>(),
);

export const recoverPassword = createAction(
  '[auth] RECOVER_PASSWORD',
  props<{ payload: Recovery }>(),
);
export const recoverPasswordSuccess = createAction(
  '[auth] RECOVER_PASSWORD_SUCCESS',
);

export const recoverPasswordFail = createAction(
  '[auth] RECOVER_PASSWORD_FAIL',
  props<{ error: string }>(),
);

export const resetPassword = createAction(
  '[auth] RESET_PASSWORD',
  props<{ payload: ResetData }>(),
);

export const resetPasswordSuccess = createAction(
  '[auth] RESET_PASSWORD_SUCCESS',
);

export const updateProfile = createAction(
  '[auth] UPDATE_CUSTOMER_PROFILE',
  props<{ payload: UpdateUser }>(),
);

export const generateLink = createAction(
  '[auth] GENERATE_LINK',
  props<{ payload: Recovery }>(),
);

export const generateLinkSuccess = createAction('[auth] GENERATE_LINK_SUCCESS');

export const generateLinkFail = createAction(
  '[auth] GENERATE_LINK_FAIL',
  props<{ error: string }>(),
);

export const loginByLink = createAction(
  '[auth] LOGIN_BY_lINK',
  props<{ payload: IUser & { uuid: string } }>(),
);

export const generateOtp = createAction(
  '[auth] GENERATE_OTP',
  props<{ payload: OtpRequest }>(),
);

export const generateOtpSuccess = createAction(
  '[auth] GENERATE_OTP_SUCCESS',
  props<{ data: any }>(),
);

export const generateOtpFail = createAction(
  '[auth] GENERATE_OTP_FAIL',
  props<{ error: string }>(),
);

export const loginByCode = createAction(
  '[auth] LOGIN_BY_CODE',
  props<{ payload: OtpLogin }>(),
);

export const loginRegionRedirect = createAction(
  '[auth] LOGIN_REGION_REDIRECT',
  props<{ payload: IUser & { uuid: string } }>(),
);

export const displayToastr = createAction('[Toastr] Display Toastr');

export const authActions = {
  init,
  login,
  loginSuccess,
  loginFail,
  logout,
  set,
  register,
  registerSuccess,
  registerFail,
  displayToastr,
  recoverPassword,
  recoverPasswordSuccess,
  recoverPasswordFail,
  resetPassword,
  resetPasswordSuccess,
  updateProfile,
  generateLink,
  generateLinkSuccess,
  generateLinkFail,
  loginByLink,
  generateOtp,
  generateOtpSuccess,
  generateOtpFail,
  loginByCode,
  loginRegionRedirect,
};
