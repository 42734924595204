import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ApiService } from '../api/api.service';
import {
  IProduct,
  IProductQueryParam,
  IProducts,
  ProductsSearchQuery,
} from 'src/app/models/product.model';
import environment from 'src/environments/environment';
import { ICategory } from '@ea/models';
import { ProductDetails } from 'src/app/models/productDetails.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  fabricPriceListId = environment.fabricPriceListId.priceListId;
  choose$ = new BehaviorSubject<boolean>(false);
  constructor(private api: ApiService) {}

  list(query: IProductQueryParam): Observable<ProductDetails> {
    return this.api.get<ProductDetails>(
      `/products/Get/ProductDetails?region=${environment.stripe.country}`,
      {
        ...query,
      }
    );
  }

  search(query: ProductsSearchQuery) {
    return this.api.get<IProducts>(
      `/product/search?region=${environment.stripe.country}`,
      query
    );
  }

  getProductSku(productSku: string): Observable<ProductDetails> {
    return this.list({
      productSku,
      priceListId: `${this.fabricPriceListId}`,
    }).pipe(
      map((data) => {
        if (data) {
          return data;
        } else {
          throw new Error('Product not found.');
        }
      })
    );
  }

  getBreadcrumbs(sku: ICategory) {
    return this.api.get<IProduct>(
      `/Fabric/Offers/Price/Item/BySku/Get/Category?sku=${sku}&region=${environment.stripe.country}`
    );
  }
}
